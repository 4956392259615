import * as React from 'react';
import { OptionalFeature } from '../../../Data/SetRules';
import { CardSetHelper } from '../../../Helpers/CardSetHelper';
import { ConfigTable } from '../../Components/ConfigTable';
import { CheckBox } from '../../Components/Form/Checkbox';
import { GenerationConfigContext } from "../../Context";

type RowProps = {
    optionalFeature: string
}

const OptionalFeatureRow = (props: RowProps) => {

    const { generationConfig, setGenerationConfig } = React.useContext(GenerationConfigContext);

    const isSelected = generationConfig.optionalFeatures.includes(props.optionalFeature);

    function changed(newValue: boolean) {

        if (newValue) {
            generationConfig.optionalFeatures.push(props.optionalFeature);
        } else {
            generationConfig.optionalFeatures = generationConfig.optionalFeatures.filter(x => x !== props.optionalFeature);
        }

        setGenerationConfig({ ...generationConfig });
    }

    return <tr className="hover:cursor-pointer" onClick={() => changed(!isSelected)}>
        <td>
            {props.optionalFeature}
        </td>
        <td className='text-right'>
            <CheckBox
                value={isSelected}
                setValue={changed}
            />
        </td>
    </tr>;
}

export const OptionalFeatureChooser = () => {

    const { generationConfig } = React.useContext(GenerationConfigContext);

    const optionalFeatures: OptionalFeature[] = [];

    for (const set of generationConfig.sets) {

        const rules = CardSetHelper.getRules(set);

        if (rules !== undefined && rules.optionalFeatures !== undefined) {
            optionalFeatures.push(...rules.optionalFeatures);
        }
    }

    if (optionalFeatures.length === 0) {
        return null;
    }

    return <ConfigTable title="Optional Features">
        {optionalFeatures.map(x => <OptionalFeatureRow key={x.name} optionalFeature={x.name} />)}
    </ConfigTable>;
}
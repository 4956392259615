import { Subtitle } from "./Typography/Subtitle";

type Props = {
    title: string,
    children: React.ReactNode
}

export const ConfigTable = (props: Props) => {
    return <div className="mb-2">
        <Subtitle>
            {props.title}
        </Subtitle>
        <table className="w-full">
            <tbody>
                {props.children}
            </tbody>
        </table>
    </div>;
}
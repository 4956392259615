export type SubtitleProps = {
    /** 
     * Content
     */
    children: React.ReactNode;
}

const className = "text-xl font-normal mb-2 border-b-2 border-slate-600 text-white";

export const Subtitle = (props: SubtitleProps) => {

    return <h2 className={className}>
        {props.children}
    </h2>;
}
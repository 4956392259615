import { Title } from "../../Components/Typography/Title";
import { Generate } from "../Generate";
import { Reset } from "../Reset";
import { OptionalCardChooser } from "./OptionalCardChooser";
import { OptionalFeatureChooser } from "./OptionalFeatureChooser";
import { SetChooser } from "./SetChooser";
import { SettingsChooser } from "./SettingsChooser";

export const ConfigForm = () => {

    return <div className="bg-slate-800 text-slate-300 p-2 rounded-lg">
        <Title>
            Configuration
        </Title>
        <SetChooser />
        <SettingsChooser />
        <OptionalCardChooser />
        <OptionalFeatureChooser />
        <div className="flex flex-col justify-center border-t-2 mt-2">
            <Generate />
            <Reset />
        </div>
    </div>;
}
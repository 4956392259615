import * as React from 'react';
import { StringHelper } from '../../../Helpers/StringHelper';
import { ConfigTable } from '../../Components/ConfigTable';
import { CheckBox } from '../../Components/Form/Checkbox';
import { CardsContext, GenerationConfigContext } from "../../Context";

type RowProps = {
    optionalCardType: string
}

const OptionalCardRow = (props: RowProps) => {

    const { generationConfig, setGenerationConfig } = React.useContext(GenerationConfigContext);

    const isSelected = generationConfig.additionalCardTypes.includes(props.optionalCardType);

    function changed(newValue: boolean) {

        if (newValue) {
            generationConfig.additionalCardTypes.push(props.optionalCardType);
        } else {
            generationConfig.additionalCardTypes = generationConfig.additionalCardTypes.filter(x => x !== props.optionalCardType);
        }

        setGenerationConfig({ ...generationConfig });
    }

    return <tr className="hover:cursor-pointer" onClick={() => changed(!isSelected)}>
        <td>
            {StringHelper.pluralize(props.optionalCardType)}
        </td>
        <td className='text-right'>
            <CheckBox
                value={isSelected}
                setValue={changed}
            />
        </td>
    </tr>;
}

export const OptionalCardChooser = () => {

    const { generationConfig } = React.useContext(GenerationConfigContext);
    const cards = React.useContext(CardsContext);

    const optionalCardTypes: string[] = [];

    for (const setKey of generationConfig.sets) {
        const set = cards.CardSets[setKey];
        if (set === undefined) {
            continue;
        }

        for (const edition of Object.keys(set)) {
            for (const ot of set[edition].optionalCardTypes) {
                if (!optionalCardTypes.includes(ot)) {
                    optionalCardTypes.push(ot);
                }
            }
        }
    }

    if (optionalCardTypes.length === 0) {
        return null;
    }

    return <ConfigTable title="Optional Cards">
        {optionalCardTypes.map(x => <OptionalCardRow key={x} optionalCardType={x} />)}
    </ConfigTable>;
}
import { Card } from "../../Entitites/Card";

export type PostRequire = {
    name: string,
    isNeeded: (cards: Card[]) => Card[],
    canSatisfy: (card: Card) => boolean
}

export const postRequires: PostRequire[] = [
    {
        name: "Require Attack Defence",
        isNeeded: (cards: Card[]) => cards.filter(c => c.types.includes("Attack")),
        canSatisfy: (card: Card) => {

            if (card.types.includes("Reaction") && card.text.includes("Attack")) {
                return true;
            }

            if (card.text.includes("Attack card, it doesn't affect you")) {
                return true;
            }

            return false;
        }
    }
];
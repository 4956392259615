type Props = {
    className?: string,
    value: boolean,
    setValue: (newValue: boolean) => any
}

export const CheckBox = (props: Props) => {

    return <input
        className={props.className}
        type="checkbox"
        checked={props.value}
        onChange={e => props.setValue(e.target.checked)}
    />;
}
import * as React from 'react';
import { Button } from '../../../Components/Interactions/Button';
import { CardPopupContext } from '../../../Context';

type Props = {
    className?: string
}

export const ClosePopup = (props: Props) => {

    const { hide } = React.useContext(CardPopupContext);

    return <Button
        className={props.className}
        type="nav"
        text='Close'
        title='Close Popup'
        action={hide}
    />;
}

export const NextPopupCard = (props: Props) => {

    const { next } = React.useContext(CardPopupContext);

    return <Button
        className={props.className}
        type="nav"
        text='Next'
        title='Next Popup Card'
        action={next}
    />;
}

export const PreviousPopupCard = (props: Props) => {

    const { previous } = React.useContext(CardPopupContext);

    return <Button
        className={props.className}
        type="nav"
        text='Previous'
        title='Previous Popup Card'
        action={previous}
    />;
}
import React from 'react';
import { Card } from '../Entitites/Card';
import { GenerationConfig } from '../Entitites/GenerationConfig';
import { CardsMeta } from '../Entitites/MetaObjects/CardsMeta';
import { GeneratedKingdomMeta } from '../Entitites/MetaObjects/GeneratedKingdomMeta';

export const CardsContext = React.createContext<CardsMeta>(undefined!);
export const CardsContextProvider = CardsContext.Provider;

type GenerationConfigHandler = {
    generationConfig: GenerationConfig,
    setGenerationConfig: (newGenerationConfig: GenerationConfig) => any
}

export const GenerationConfigContext = React.createContext<GenerationConfigHandler>(undefined!);
export const GenerationConfigContextProvider = GenerationConfigContext.Provider;


type GeneratedKingdomHandler = {
    generatedKingdom: GeneratedKingdomMeta | undefined,
    generate: () => any
}

export const GeneratedKingdomContext = React.createContext<GeneratedKingdomHandler>(undefined!);
export const GeneratedKingdomContextProvider = GeneratedKingdomContext.Provider;

export const CardPopupContext = React.createContext<CardPopupStateHandler>(undefined!);
export const CardPopupContextProvider = CardPopupContext.Provider;

export type CardPopupState = {
    isVisible: boolean,
    card: Card | undefined
}

export type CardPopupHandler = {
    show: (card: string) => any,
    hide: () => any,
    next: () => any,
    previous: () => any
}

export type CardPopupStateHandler = CardPopupHandler & {
    state: CardPopupState
}
import * as React from 'react';
import { filters } from '../../../Data/KingdomModifiers/Filters';
import { postRequires } from '../../../Data/KingdomModifiers/PostRequires';
import { requires } from '../../../Data/KingdomModifiers/Requires';
import { GenerationConfig } from '../../../Entitites/GenerationConfig';
import { ConfigTable } from '../../Components/ConfigTable';
import { CheckBox } from '../../Components/Form/Checkbox';
import { GenerationConfigContext } from "../../Context";

type RowProps = {
    name: string,
    value: boolean,
    setValue: (newValue: boolean) => any
}

const SettingsRow = (props: RowProps) => {


    return <tr className="hover:cursor-pointer" onClick={() => props.setValue(!props.value)}>
        <td>
            {props.name}
        </td>
        <td className='text-right'>
            <CheckBox
                value={props.value}
                setValue={props.setValue}
            />
        </td>
    </tr>;
}

export const SettingsChooser = () => {

    const { generationConfig, setGenerationConfig } = React.useContext(GenerationConfigContext);

    function changed(newConfig: GenerationConfig) {
        setGenerationConfig({ ...newConfig });
    }

    const rows: RowProps[] = [

        ...requires.map(x => {
            const value = generationConfig.requires.includes(x.name);

            return {
                name: x.name,
                value,
                setValue: (newValue: boolean) => {
                    if (newValue && !value) {
                        generationConfig.requires.push(x.name);
                    } else if (!newValue) {
                        generationConfig.requires = generationConfig.requires.filter(y => y !== x.name);
                    }

                    changed(generationConfig);
                }
            }
        }),

        ...postRequires.map(x => {
            const value = generationConfig.postRequires.includes(x.name);

            return {
                name: x.name,
                value,
                setValue: (newValue: boolean) => {
                    if (newValue && !value) {
                        generationConfig.postRequires.push(x.name);
                    } else if (!newValue) {
                        generationConfig.postRequires = generationConfig.postRequires.filter(y => y !== x.name);
                    }

                    changed(generationConfig);
                }
            }
        }),

        ...filters.map(x => {

            const value = generationConfig.filters.includes(x.name);

            return {
                name: x.name,
                value,
                setValue: (newValue: boolean) => {
                    if (newValue && !value) {
                        generationConfig.filters.push(x.name);
                    } else if (!newValue) {
                        generationConfig.filters = generationConfig.filters.filter(y => y !== x.name);
                    }

                    changed(generationConfig);
                }
            }
        })
    ];

    return <ConfigTable title="Settings">
        {rows.map(x => <SettingsRow key={x.name} {...x} />)}
    </ConfigTable>;
}
import classNames from 'classnames';
import * as React from 'react';
import { Icon } from '../Typography/Icon';

export type ButtonProps = {
    /**
     * The type of the button
     */
    type: ButtonTypes;
    /**
    * Label of the button
    */
    text?: string;
    /**
    * Tooltip of the button
    */
    title?: string;
    /**
    * Additional classnames for the button
    */
    className?: string;
    /**
    * Icon of the button
    */
    icon?: string;
    /**
    * Confirm popup text of the button
    */
    confirm?: string;
    /**
    * Whether or not the button is disabled
    */
    isDisabled?: boolean;
    /**
    * Whether or not the button is pulled right in a flex context
    */
    isPulledRight?: boolean;
    /**
    * Whether the button acts to submit forms
    */
    isSubmit?: boolean;
    isSmall?: boolean;
    /**
     * Button click action
    */
    action(): any,
}

export type ButtonTypes = "primary" | "nav" | "warning" | "danger";

const baseClassName = 'rounded-md shadow py-1 px-2 font-normal tracking-wider text-white disabled:cursor-not-allowed';
const isSmallClassName = "text-xs";

const typeStyles = {
    nav: `bg-nav-600 hover:bg-nav-500 disabled:bg-nav-700`,
    primary: `bg-primary-600 hover:bg-primary-500 disabled:bg-primary-700`,
    warning: `bg-warning-400 hover:bg-warning-500 disabled:bg-warning-200`,
    danger: `bg-danger-700 hover:bg-danger-600 disabled:bg-danger-800`
};

export const Button = (props: ButtonProps) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const isCancelled = React.useRef(false);

    React.useEffect(() => {
        return () => {
            isCancelled.current = true;
        };
    }, []);

    function finishExecution(loading: boolean) {
        setIsLoading(loading);
    }

    function click() {
        if (!props.confirm || props.confirm.length === 0 || window.confirm(props.confirm)) {
            setIsLoading(true);
            const result = props.action();

            if (result === undefined) {
                finishExecution(false);
            } else if (typeof result === "boolean") {
                if (!isCancelled.current) {
                    finishExecution(!result);
                }
            } else if (typeof result.then === "function" && typeof result.catch === "function") {
                result
                    .then((r: any) => {
                        if (!isCancelled.current) {
                            finishExecution(false);
                        }
                    })
                    .catch((_: any) => {
                        if (!isCancelled.current) {
                            finishExecution(true);
                        }
                    });
            } else {
                finishExecution(false);
            }
        }
    }

    const title = props.title ?? props.text ?? "";

    const className = classNames(
        baseClassName,
        typeStyles[props.type],
        {
            "absolute": props.isPulledRight,
            "right-0": props.isPulledRight
        },
        props.isSmall ? isSmallClassName : "",
        props.className,
        props.isDisabled ? "opacity-50" : ""
    );

    const iconClassName = props.text !== undefined && props.text.length > 0 ? "mr-1" : "";

    return <button
        type={props.isSubmit ? "submit" : "button"}
        onClick={click}
        title={title}
        className={className}
        disabled={isLoading || props.isDisabled}
    >
        {props.icon !== undefined && <Icon icon={props.icon} title={title} className={iconClassName} />}
        <span>{props.text}</span>
    </button>;
};

Button.defaultProps = {
    type: "nav"
};
import { Card } from "../Entitites/Card";
import { ImportCard } from "../Entitites/ImportCard";

export class ImportHelper {

    public static importCards(importCards: ImportCard[]): Card[] {

        return importCards.map(x => {
            return {
                ...x,
                text: x.text.replaceAll("–", "-")
            }
        });
    }
}
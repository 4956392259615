import * as React from 'react';
import './Css/index.css';
import { cardsData } from './Data/data';
import { GenerationConfig } from './Entitites/GenerationConfig';
import { GeneratedKingdomMeta } from './Entitites/MetaObjects/GeneratedKingdomMeta';
import { CardSetBuilder } from './Helpers/CardSetBuilder';
import { GenerationConfigHelper } from './Helpers/GenerationConfigHelper';
import { GenerationHelper } from './Helpers/GenerationHelper';
import { ImportHelper } from './Helpers/ImportHelper';
import { CardPopupContextProvider, CardPopupHandler, CardPopupState, CardPopupStateHandler, CardsContextProvider, GeneratedKingdomContextProvider, GenerationConfigContextProvider } from './UI/Context';
import { createPopupHandler } from './UI/PopupHandlerContext';
import { ConfigForm } from './UI/Sections/Config/ConfigForm';
import { PopupCardRender } from './UI/Sections/Result/Popup/PopupCardRender';
import { ResultRender } from './UI/Sections/Result/ResultRender';
import { VersionFooter } from './UI/Sections/VersionFooter';

function loadAll() {

  const imported = ImportHelper.importCards(cardsData);
  const cards = CardSetBuilder.toCardSets(imported);

  return cards;
}

const defaultHandler = {
  show: (_: string) => { },
  hide: () => { },
  next: () => { },
  previous: () => { }
};

function App() {

  const [cards,] = React.useState(loadAll());
  const [generationConfig, setGenerationConfig] = React.useState(GenerationConfigHelper.load());
  const [generatedKingdom, setGeneratedKingdom] = React.useState<GeneratedKingdomMeta | undefined>(undefined);
  const [cardPopupState, setCardPopupState] = React.useState<CardPopupState>({ card: undefined, isVisible: false });
  const [cardPopupHandler, setCardPopupHandler] = React.useState<CardPopupHandler>(defaultHandler);

  function changeGenerationConfig(newGenerationConfig: GenerationConfig) {
    console.log(generationConfig);

    setGenerationConfig(newGenerationConfig);
  }

  function generate() {

    GenerationConfigHelper.saveGenerationConfig(generationConfig);

    const generated = GenerationHelper.generate(cards, generationConfig);

    setGeneratedKingdom(generated);

    console.log(generated);

    const handler = createPopupHandler(generated, setCardPopupState);

    setCardPopupHandler(handler);
  }


  const cardPopup: CardPopupStateHandler = {
    ...cardPopupHandler,
    state: cardPopupState
  }

  return (
    <CardsContextProvider value={cards}>
      <GenerationConfigContextProvider value={{ generationConfig, setGenerationConfig: changeGenerationConfig }}>
        <GeneratedKingdomContextProvider value={{ generatedKingdom, generate }}>
          <CardPopupContextProvider value={cardPopup}>
            <>
              <div className="max-md:p-2 md:p-4 flex flex-col md:flex-row max-md:space-y-2 md:space-x-4 z-0">
                <div className="min-w-fit">
                  <ConfigForm />
                </div>
                <div className='grow'>
                  <ResultRender />
                  <VersionFooter />
                </div>
              </div>
              <PopupCardRender />
            </>
          </CardPopupContextProvider>
        </GeneratedKingdomContextProvider>
      </GenerationConfigContextProvider>
    </CardsContextProvider>
  );
}

export default App;

import classNames from "classnames";

export type IconTypes = "normal" | "danger" | "primary" | "warning";

export type IconProps = {
    /**
    * Material design icon type
   */
    icon: string;
    /** 
     * Text for tooltip
     */
    title?: string;
    /** 
    * Type of icon
    */
    type: IconTypes;
    className?: string;
}

const typeClassNames = {
    normal: "",
    danger: "text-danger-400",
    warning: "text-warning-400",
    primary: "text-primary-400"
}

export const Icon = (props: IconProps) => {

    const className = classNames("mdi mdi-" + props.icon,
        typeClassNames[props.type],
        props.className);

    return <span className={className} aria-hidden="true" title={props.title} />;
}

Icon.defaultProps = {
    type: "normal"
}
import { Card } from "../../../Entitites/Card";

type Props = {
    card: Card
}

export const CardImage = (props: Props) => {

    return <img
        src={props.card.url}
        alt={props.card.name}
        className="max-h-[400px]"
    />;
}

import pluralize from "pluralize";

export class StringHelper {

    public static pluralize(text: string) {
        return pluralize(text);
    }

    public static pluralizeFromCount(count: number, text: string) {
        return pluralize(text, count);
    }
}
import Cookies, { CookieSetOptions } from "universal-cookie";

export type RecentSolarSystem = {
    solarSystemId: number,
    name: string
}

export class CookieHelper {

    private static _cookies: Cookies = new Cookies();
    private static createOptions(): CookieSetOptions {
        return {
            path: '/',
            maxAge: 31536000
        };
    }

    public static stringFromCookies(name: string): string | undefined {
        const value = this._cookies.get(name);
        if (value && value.length > 0 && value !== "undefined" && value !== "null") {
            return value;
        }

        return undefined;
    }

    public static numberFromCookies(name: string): number | undefined {
        const stringValue = this.stringFromCookies(name);
        const number = Number(stringValue);

        return isNaN(number) ? undefined : number;
    }

    public static valueToCookie<T>(name: string, value: T | undefined): T | undefined {
        if (value && typeof value === "string" && value.length === 0) {
            value = undefined;
        }
        if (value) {
            this._cookies.set(name, value, this.createOptions());
        } else {
            this._cookies.remove(name, this.createOptions());
        }
        return value;
    }

    public static valueFromCookie<T>(name: string): T | undefined {
        const value = this._cookies.get(name);

        if (value === undefined || value === null) {
            return undefined;
        }

        return value as T;
    }

    public static isCookieConsentGiven() {
        return this._cookies.get("allowCookies") === "yes";
    }

    public static setCookieConsent(consented: boolean) {
        if (consented) {
            this._cookies.set("allowCookies", "yes", {
                path: "/",
                maxAge: 31536000
            });
        } else {
            this._cookies.remove("allowCookies");
        }
    }
}
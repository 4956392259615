import { filters } from "../Data/KingdomModifiers/Filters";
import { postRequires } from "../Data/KingdomModifiers/PostRequires";
import { requires } from "../Data/KingdomModifiers/Requires";
import { GenerationConfig, defaultGenerationConfig } from "../Entitites/GenerationConfig";
import { CookieHelper } from "./CookieHelper";

export class GenerationConfigHelper {

    public static load(): GenerationConfig {

        const fromCookies = CookieHelper.valueFromCookie<GenerationConfig>("config");
        const defaultConfig = this.default();

        if (fromCookies !== undefined) {

            return {
                ...defaultConfig,
                ...fromCookies
            }
        }

        return defaultConfig;
    }

    public static default(): GenerationConfig {
        return { ...defaultGenerationConfig };
    }

    public static saveGenerationConfig(config: GenerationConfig) {
        CookieHelper.valueToCookie("config", config);
    }

    public static getConfiguredFilters(config: GenerationConfig) {

        const selected = [];

        for (const filter of filters) {
            if (config.filters.includes(filter.name)) {
                selected.push(filter);
            }
        }

        return selected;
    }

    public static getConfiguredRequires(config: GenerationConfig) {

        const selected = [];

        for (const require of requires) {
            if (config.requires.includes(require.name)) {
                selected.push(require);
            }
        }

        return selected;
    }

    public static getConfiguredPostRequires(config: GenerationConfig) {

        const selected = [];

        for (const postRequire of postRequires) {
            if (config.postRequires.includes(postRequire.name)) {
                selected.push(postRequire);
            }
        }

        return selected;
    }
}
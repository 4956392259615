export type SubtitleProps = {
    /** 
     * Content
     */
    children: React.ReactNode;
}

const className = "text-2xl font-normal mb-4 border-b-2 border-slate-600 text-white";

export const Title = (props: SubtitleProps) => {

    return <h1 className={className}>
        {props.children}
    </h1>;
}
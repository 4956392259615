import { Card } from "../Entitites/Card";
import { GenerationConfig } from "../Entitites/GenerationConfig";
import { CardsMeta } from "../Entitites/MetaObjects/CardsMeta";
import { GeneratedKingdomMeta } from "../Entitites/MetaObjects/GeneratedKingdomMeta";
import { KingdomGenerationContext } from "../Entitites/MetaObjects/KingdomGenerationContext";
import { CardSetHelper } from "./CardSetHelper";
import { RandomHelper } from "./RandomHelper";

export type GenerationResult = {
    kingdom: Card[],
    optional: Card[],
    logs: string[]
};


export class GenerationHelper {

    public static generate(cards: CardsMeta, generationConfig: GenerationConfig): GeneratedKingdomMeta {

        const generationContext = new KingdomGenerationContext(cards, generationConfig);

        const { generatedCards, logs } = generationContext.generate();

        const chosenSets = cards.getSets(generationConfig.sets);

        const otherCards = cards.OtherCards.filter(x => generationConfig.sets.includes(x.set));

        const optional = [...new Set(generationConfig.additionalCardTypes)].map(x => this.selectOneOfType(x, otherCards));

        for (const set of chosenSets) {

            const rules = CardSetHelper.getRules(set.fullName);

            if (rules !== undefined && rules.optionalFeatures !== undefined) {
                for (const feature of rules.optionalFeatures) {
                    if (generationConfig.optionalFeatures.includes(feature.name)) {
                        for (const cardName of feature.cards) {
                            const card = cards.OtherCards.find(x => x.name === cardName);

                            if (card !== undefined) {
                                optional.push(card);
                            } else {
                                console.log(`Couldn't find ${cardName}`);
                            }
                        }
                    }
                }
            }
        }

        return new GeneratedKingdomMeta(
            cards,
            generatedCards,
            optional.filter(x => x !== undefined).map(x => x!),
            generationConfig,
            logs
        );
    }

    private static selectOneOfType(type: string, cards: Card[]) {

        const available = cards.filter(x => x.types.includes(type));

        if (available.length === 0) {
            return undefined;
        }

        const idx = RandomHelper.intInRange(0, available.length - 1);

        return available[idx];
    }
}
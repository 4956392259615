import classNames from "classnames";

type Props = {
    set: string,
    className?: string
}

export const SetIcon = (props: Props) => {

    const fixed = props.set
        .replaceAll(" 1E", "")
        .replaceAll(" 2E", "")
        .replaceAll(" ", "_");

    const className = classNames("invert h-[15px] mx-auto", props.className);

    return <img
        src={`${process.env.PUBLIC_URL}/images/sets/${fixed}_icon.png`}
        className={className}
        alt={props.set}
    />
}
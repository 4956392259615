import * as React from 'react';
import { Card } from "../../../Entitites/Card";
import { SetIcon } from "../../Components/SetIcon";
import { CardPopupContext } from "../../Context";
import { CardImage } from "./CardImage";

type Props = {
    card: Card
}

export const CardRender = (props: Props) => {

    const cardPopup = React.useContext(CardPopupContext);

    function click() {
        cardPopup.show(props.card.name);
    }

    return <>
        <div className="mr-1 mb-2 flex flex-col">
            <button className="cursor-pointer relative" onClick={click}>
                <CardImage {...props} />
            </button>
            <div className="flex justify-center text-s text-white w-full mt-1">
                <span className="flex flex-row bg-black rounded-lg md:py-1 md:px-2 max-md:h-[22px] max-md:w-[30px]">
                    <SetIcon set={props.card.set} className="md:mr-1 my-auto" />
                    <span className="text-center max-md:hidden">
                        {props.card.set}
                    </span>
                </span>
            </div>
        </div>

    </>;
}
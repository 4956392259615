import { GeneratedKingdomMeta } from "../../../Entitites/MetaObjects/GeneratedKingdomMeta";
import { StringHelper } from '../../../Helpers/StringHelper';
import { Subtitle } from '../../Components/Typography/Subtitle';
import { CardRender } from "./CardRender";

type Props = {
    generatedKingdom: GeneratedKingdomMeta
}

export const RequiredOtherRender = (props: Props) => {

    const requiredOther = props.generatedKingdom.RequiredOther;

    const otherTypes = requiredOther
        .cardTypes.map(x => StringHelper.pluralize(x))
        .join(", ")
        .replaceAll("[", "")
        .replaceAll("]", "");

    const anyCards = requiredOther.portrait.length !== 0
        || requiredOther.landscape.length !== 0

    if (requiredOther.cardTypes.length === 0 && !anyCards) {
        return null;
    }

    return <>
        <Subtitle>
            Additional Cards
        </Subtitle>
        <p className="text-l mb-4">
            You will also need {otherTypes}{otherTypes.length > 0 && anyCards ? " and" : ""}
        </p>
        {requiredOther.portrait.length > 0 &&
            <div className="grid grid-cols-5">
                {requiredOther.portrait.map(x => <CardRender key={x.name} card={x} />)}
            </div>
        }
        {requiredOther.landscape.length > 0 &&
            <div className="grid grid-cols-3">
                {requiredOther.landscape.map(x => <CardRender key={x.name} card={x} />)}
            </div>
        }
    </>;
}
import { GeneratedKingdomMeta } from "../Entitites/MetaObjects/GeneratedKingdomMeta";
import { CardPopupHandler, CardPopupState } from "./Context";


class CardPopupManager {

    public get isVisible() { return this.card !== undefined };

    public get card() { return this._idx !== undefined ? this._kingdom.AllCards[this._idx] : undefined }

    private _idx: number | undefined = undefined;
    private readonly _kingdom: GeneratedKingdomMeta;

    constructor(generatedKingdom: GeneratedKingdomMeta) {
        this._kingdom = generatedKingdom;
    }

    public show(card: string) {
        this._idx = this._kingdom.AllCards.findIndex(x => x.name === card);

        return this.state();
    }

    public hide() {
        this._idx = undefined;

        return this.state();
    }

    public next() {
        if (this._idx === undefined) {
            return this.state();
        }

        this._idx++;
        if (this._idx >= this._kingdom.AllCards.length) {
            this._idx = 0;
        }

        return this.state();
    }

    public previous() {
        if (this._idx === undefined) {
            return this.state();
        }

        this._idx--;
        if (this._idx < 0) {
            this._idx = this._kingdom.AllCards.length - 1;
        }

        return this.state();
    }

    public state(): CardPopupState {
        return {
            isVisible: this.isVisible,
            card: this.card
        }
    }
}

export const createPopupHandler = (kingdom: GeneratedKingdomMeta, setCardPopupState: (state: CardPopupState) => any): CardPopupHandler => {

    const cardPopupManager = new CardPopupManager(kingdom);

    function show(card: string) {
        const state = cardPopupManager?.show(card);
        if (state !== undefined) {
            setCardPopupState(state);
        }
    }

    function hide() {
        const state = cardPopupManager?.hide();
        if (state !== undefined) {
            setCardPopupState(state);
        }
    }

    function next() {
        const state = cardPopupManager?.next();
        if (state !== undefined) {
            setCardPopupState(state);
        }
    }

    function previous() {
        const state = cardPopupManager?.previous();
        if (state !== undefined) {
            setCardPopupState(state);
        }
    }

    return {
        next, previous, hide, show
    };
}
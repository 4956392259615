import * as React from 'react';
import { GenerationConfigHelper } from '../../Helpers/GenerationConfigHelper';
import { Button } from '../Components/Interactions/Button';
import { GenerationConfigContext } from '../Context';

export const Reset = () => {

    const { setGenerationConfig } = React.useContext(GenerationConfigContext);

    function reset() {
        const generationConfig = GenerationConfigHelper.default();
        setGenerationConfig(generationConfig);
    }

    return <Button
        action={reset}
        text='Reset'
        type='danger'
        className='mt-2 w-full'
    />
}
import { Card } from "../../../Entitites/Card";
import { Require } from "../Requires";

const exclusions = [
    "Procession",
    "Treasure Map"
]

export const require_Trashing: Require = {
    name: "Require Trashing",
    canSatisfy: (card: Card) => {
        if (exclusions.includes(card.name) || card.trash.length === 0) {
            return false;
        }

        // Trash targets are split by commas
        const parts = card.trash.split(", ");

        // If the card only trashes itself or something from the supply then it isn't valid
        if (parts.length === 1) {
            const toCheck = parts[0];

            if (toCheck.includes("Self") || toCheck.includes("Supply")) {
                return false;
            }
        }

        return true;
    }
} 
import * as React from 'react';
import { CardPopupContext } from '../../../Context';
import { CardImage } from '../CardImage';
import { ClosePopup, NextPopupCard, PreviousPopupCard } from './PopupNav';

export const PopupCardRender = () => {

    const { state, hide, next, previous } = React.useContext(CardPopupContext);

    const handleKeyPress = React.useCallback((event: any) => {
        if (state.isVisible) {
            switch (event.key) {
                case "ArrowLeft":
                case "a":
                    previous();
                    break;
                case "ArrowRight":
                case "d":
                    next();
                    break;
                case "Escape":
                case "x":
                    hide();
                    break;
            }
        }
    }, [state, hide, next, previous]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    if (state === undefined || !state.isVisible || state.card === undefined) {
        return null;
    }

    return <div className="modal w-screen h-screen min-h-screen justify-center items-center fixed inset-0 z-10">
        <div className="opacity-50 bg-black w-full h-full" />
        <ClosePopup className='absolute top-0 right-0 max-md:m-2 md:m-4' />
        <div className="absolute inset-0 z-20 w-fit h-fit m-auto">
            <CardImage card={state.card} />
            <div className="flex flex-row space-x-2 mt-2" >
                <PreviousPopupCard className='w-[50%]' />
                <NextPopupCard className='w-[50%]' />
            </div>
        </div>
    </div>;
}
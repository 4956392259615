import * as React from 'react';
import { CardSetEditions } from '../../../Entitites/CardSet';
import { NonEditionName } from '../../../Helpers/CardSetHelper';
import { ConfigTable } from '../../Components/ConfigTable';
import { CheckBox } from '../../Components/Form/Checkbox';
import { SetIcon } from '../../Components/SetIcon';
import { CardsContext, GenerationConfigContext } from "../../Context";

type RowProps = {
    setEditions: CardSetEditions
}

const SetRow = (props: RowProps) => {

    const { generationConfig, setGenerationConfig } = React.useContext(GenerationConfigContext);

    const allNames = Object.keys(props.setEditions).map(x => props.setEditions[x].fullName);
    const isMulti = Object.keys(props.setEditions).length > 1;
    const coreSet = props.setEditions[NonEditionName];

    function changed(newValue: boolean, edition: string) {

        generationConfig.sets = generationConfig.sets.filter(x => !allNames.includes(x));

        if (newValue) {

            const core = coreSet.fullName;
            const editionName = Object.values(props.setEditions).find(x => x.fullName === edition)?.fullName ?? core;

            generationConfig.sets.push(...[core, editionName]);
            generationConfig.sets = [...new Set(generationConfig.sets)]
        }

        setGenerationConfig({ ...generationConfig });
    }

    function rowClicked() {
        if (isMulti) {
            for (const set of Object.keys(props.setEditions)) {
                const setName = props.setEditions[set].fullName;

                if (generationConfig.sets.includes(setName)) {
                    changed(false, setName);
                    return;
                }
            }

            const lastSet = Object.keys(props.setEditions)
                .filter(x => x !== NonEditionName)
                .sort((a, b) => a > b ? -1 : 1)[0];

            changed(true, props.setEditions[lastSet].fullName);

        } else {
            changed(!generationConfig.sets.includes(coreSet.fullName), NonEditionName);
        }
    }

    return <tr className="hover:cursor-pointer" onClick={() => rowClicked()}>
        <td style={{ alignContent: "middle" }}>
            <SetIcon set={coreSet.fullName} />
        </td>
        <td style={{ verticalAlign: "middle" }}>
            <span className="ml-2">
                {coreSet.setName}
            </span>
        </td>
        <td className='text-right'>
            {isMulti && <div>
                {Object.keys(props.setEditions)
                    .filter(x => x !== NonEditionName)
                    .sort((a, b) => a < b ? -1 : 1)
                    .map(x => props.setEditions[x])
                    .map(x => <label key={x.fullName} className={`ml-2`}>
                        <CheckBox
                            className='ml-1'
                            value={generationConfig.sets.includes(x.fullName)}
                            setValue={v => changed(v, x.fullName)}
                        />
                        {x.fullName.replace(x.setName, "").trim()}
                    </label>)}
            </div>}
            {!isMulti && <label>
                <CheckBox
                    value={generationConfig.sets.includes(coreSet.fullName)}
                    setValue={v => changed(v, NonEditionName)}
                />
            </label>}
        </td>
    </tr>;
}

export const SetChooser = () => {

    const cards = React.useContext(CardsContext);

    return <ConfigTable title="Sets">
        {Object.keys(cards.CardSets).map(x => <SetRow key={x} setEditions={cards.CardSets[x]} />)}
    </ConfigTable>;
}
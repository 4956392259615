import { additionalCardTypes, additionalCards, basicCards, landscapeTypes, optionalCardTypes, stackPileCardTypes } from "../Data/CardRules";
import { Card } from "../Entitites/Card";
import { CardSetHelper } from "./CardSetHelper";


export class CardHelper {

    public static isLandscapeOrientation(card: Card) {
        for (let t of card.types) {
            if (landscapeTypes.includes(t)) {
                return true;
            }
        }

        return false;
    }

    public static isAdditional(card: Card) {
        if (additionalCards.includes(card.name)) {
            return true;
        }

        for (const type of card.types) {
            if (additionalCardTypes.includes(type)) {
                return true;
            }
        }

        if (card.text.includes("This is not in the Supply")) {
            return true;
        }

        return false;
    }

    public static isBasic(card: Card) {
        return basicCards.includes(card.name);
    }

    public static stackPile(card: Card) {
        for (const type of card.types) {
            if (stackPileCardTypes.includes(type)) {
                return type;
            }
        }
        return undefined;
    }

    public static isKingdom(card: Card) {
        return !this.isBasic(card) && !this.isAdditional(card);
    }

    public static optionalCardType(card: Card) {
        for (const type of card.types) {
            if (optionalCardTypes.includes(type)) {
                return type;
            }
        }
        return undefined;
    }

    public static isBottomOfSplitPile(card: Card) {

        const split = this.getSplitPile(card);

        return split !== undefined && split.bottom === card.name;
    }

    public static getSplitPile(card: Card) {

        const rules = CardSetHelper.getRules(card.set);

        if (rules !== undefined) {
            if (rules.splitCards !== undefined) {
                return rules.splitCards.find(x => x.top === card.name || x.bottom === card.name);
            }
        }

        return undefined;
    }

    public static getSetup(card: Card) {

        const setupKey = "Setup:";

        if (card.text.includes(setupKey)) {
            const index = card.text.indexOf(setupKey);

            return [card.text.substring(index + setupKey.length).trim().replaceAll("&#160;Favors", " Favors")]
        }

        return undefined;
    }
}
export class RandomHelper {

    public static randomSeed() {
        return this.intInRange(0, 1000000);
    }

    public static intInRange(min: number, max: number) {
        // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    public static shuffle<T>(array: T[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
}
import * as React from 'react';
import { Divider } from '../../Components/Divider';
import { Subtitle } from '../../Components/Typography/Subtitle';
import { Title } from '../../Components/Typography/Title';
import { GeneratedKingdomContext } from '../../Context';
import { CardRender } from './CardRender';
import { LogsRender } from './LogsRender';
import { RequiredOtherRender } from './RequiredOtherRender';
import { SetupRender } from './SetupRender';

export const ResultRender = () => {

    const { generatedKingdom } = React.useContext(GeneratedKingdomContext);

    if (generatedKingdom === undefined) {
        return null;
    }

    return <div className="bg-slate-800 text-slate-300 p-2 rounded-lg max-md:mb-2 md:mb-4">
        <Title>Generated Game</Title>
        <Subtitle>
            Kingdom Cards
        </Subtitle>
        <div className="grid grid-cols-5">
            {generatedKingdom.Kingdom.map(x => <CardRender key={x.name} card={x} />)}
        </div>
        <RequiredOtherRender generatedKingdom={generatedKingdom} />
        <SetupRender generatedKingdom={generatedKingdom} />
        {generatedKingdom.GenerationLogs.length > 0 &&
            <>
                <Divider />
                <LogsRender logs={generatedKingdom.GenerationLogs} />
            </>
        }
    </div>;
}
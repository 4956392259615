import { customCards as setRules } from "../Data/SetRules";
import { Card } from "../Entitites/Card";
import { CardSet } from "../Entitites/CardSet";

export const NonEditionName = "Core";

export class CardSetHelper {

    public static getRules(setName: string) {
        return setName in setRules ? setRules[setName] : undefined;
    }

    public static getAllCards(cardSets: CardSet[]): Card[] {
        const cards = [];

        for (const set of cardSets) {
            cards.push(...set.cards);
        }

        return cards;
    }

}
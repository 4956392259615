import { RandomHelper } from "../Helpers/RandomHelper";

export type GenerationConfig = {
    sets: string[],
    additionalCardTypes: string[],
    optionalFeatures: string[],
    seed: number,
    kingdomCards: number,
    filters: string[],
    requires: string[],
    postRequires: string[]
}

export const defaultGenerationConfig: GenerationConfig = {
    sets: ["Base", "Base 2E"],
    seed: RandomHelper.randomSeed(),
    kingdomCards: 10,
    additionalCardTypes: [],
    optionalFeatures: [],
    filters: [],
    requires: [],
    postRequires: []
};
import * as React from 'react';
import { Button } from '../Components/Interactions/Button';
import { GeneratedKingdomContext, GenerationConfigContext } from '../Context';

export const Generate = () => {

    const { generationConfig } = React.useContext(GenerationConfigContext);
    const { generate } = React.useContext(GeneratedKingdomContext);

    return <Button
        isDisabled={generationConfig.sets.length === 0}
        action={generate}
        text='Generate'
        type='primary'
        className='mt-2 w-full'
    />
}
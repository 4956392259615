import { Card } from "../Entitites/Card";

export type SplitCard = {
    top: string, bottom: string
}

export type SetRules = {
    customCards?: Card[],
    splitCards?: SplitCard[],
    optionalFeatures?: OptionalFeature[]
}

export type OptionalFeature = {
    name: string,
    cards: string[]
}

export const customCards: { [key: string]: SetRules } = {
    "Prosperity": {
        optionalFeatures: [
            {
                name: "Colony",
                cards: ["Colony", "Platinum"]
            }
        ]
    },
    "Dark Ages": {
        optionalFeatures: [
            {
                name: "Shelters",
                cards: ["Overgrown Estate", "Hovel", "Necropolis"]
            }
        ],
        customCards: [
            {
                name: "Knights",
                cost: "$5",
                url: "https://wiki.dominionstrategy.com/images/9/9a/Knights.jpg",
                set: "Dark Ages",
                text: "Shuffle the Knights pile before each game with it. Keep it face down except for the top card, which is the only one that can be bought or gained",
                types: ["Action", "Attack", "Knight"],
                trash: ""
            }
        ]
    },
    "Empires": {
        splitCards: [
            {
                top: "Catapult",
                bottom: "Rocks"
            },
            {
                top: "Encampment",
                bottom: "Plunder"
            },
            {
                top: "Gladiator",
                bottom: "Fortune"
            },
            {
                top: "Patrician",
                bottom: "Emporium"
            },
            {
                top: "Settlers",
                bottom: "Bustling Village"
            }
        ]
    }
};
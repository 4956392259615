import * as React from 'react';

type Props = {
    logs: string[]
}

const className = "cursor-pointer text-slate-400 hover:text-slate-600 my-2";

export const LogsRender = (props: Props) => {

    const [isVisible, setIsVisible] = React.useState(false);

    if (!isVisible) {
        return <button onClick={() => setIsVisible(true)} className={className} >Show Logs</button>
    }

    return <>
        <button onClick={() => setIsVisible(false)} className={className} >Hide Logs</button>
        {props.logs.length === 0 && <p>No logs to show</p>}
        {props.logs.length !== 0 && <ul>
            {props.logs.map(x => <li key={x}>{x}</li>)}
        </ul>}
    </>
}
import { Card } from "../../Entitites/Card"

export type Filter = {
    name: string,
    shouldBeIncluded: (card: Card) => boolean
}

export const filters: Filter[] = [
    {
        name: "No Attacks",
        shouldBeIncluded: c => !c.types.includes("Attack")
    }
]
import { GeneratedKingdomMeta } from "../../../Entitites/MetaObjects/GeneratedKingdomMeta";
import { Subtitle } from '../../Components/Typography/Subtitle';

type Props = {
    generatedKingdom: GeneratedKingdomMeta
}

export const SetupRender = (props: Props) => {

    if (props.generatedKingdom.Setup.length === 0) {
        return null;
    }

    return <>
        <Subtitle>
            Setup
        </Subtitle>
        <ul className="text-l mb-4">
            {props.generatedKingdom.Setup.map(x => <li key={x}>
                {x}
            </li>)}
        </ul>
    </>;
}